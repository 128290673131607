/* Login.css */

/* Page Container */
.flex-container_login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f0f0;
  }
  
  /* Login Box */
  .container_login {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 300px;
  }
  
  /* Logo */
  .logo {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Title */
  #log {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Form */
  #logform {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Input Fields */
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f9f9f9;
    transition: border-color 0.2s;
  }
  
  input[type="email"]:focus,
  input[type="password"]:focus {
    border-color: #007bff;
  }
  
  /* Labels */
  #login_email,
  #login_password {
    position: relative;
    left: -9999px;
  }
  
  /* Password Visibility Icon */
  .password-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #888;
  }
  
  #logeye:hover {
    color: #333;
  }
  
  /* Login Button */
  #login {
    background-color: #007bff;
    color: #fff;
    padding: 12px 0;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  #login:hover {
    background-color: #0056b3;
  }
  
  /* Forgot Password and Registration Links */
  #fgot,
  #reg {
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
    margin-top: 10px;
    transition: color 0.2s;
  }
  
  #fgot:hover,
  #reg:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  