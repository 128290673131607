* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}
.AlertMain {
    display: flex;
    padding-left: 66px;
    padding-top: 66px;

    background-color: white;
}

#Alert-head {
    height: 50px;
    margin-left: 311px;
    width: 74.4%;
    background: rgb(231, 231, 231);
    border: 1px solid rgb(83, 83, 83);
    border-radius: 1px;
    cursor: pointer;

}

#text {
    position: relative;
    top: 15px;
    left: 380px;
    text-align: center;
    font-weight: bold;
    color: rgb(9, 9, 9);
}

.plusiconalert {

    padding: 15px;
    margin-top: 38%;
    margin-left: 90px;
    position: fixed;
    color: #0f6b93;
    text-decoration: none;
    cursor: pointer;
}
#alertmsg{
position: relative;
    top: 34px;
}
#msg {
    position: relative;
    left: 300px;
    font-size: 10px;
    text-align: center;
    font-weight: bold;
    /* padding-top: 30px; */
    /* color: rgb(231, 231, 231); */
    color:#ccc;
    font-weight: bold;
    cursor: pointer;

}

.alertgrid {
    margin-left: 407px;
    /* margin-right: 429px; */
    width: 100%;
    top: 40px;
    left: -408px;
    right: 320px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    position: relative;

}

 .alertgrid label {
    color: #216c98;
    top: -20px;
    left: 10px;
    font-size: small;
    font-weight: bold;
    font-size: 12px;
    position: relative;

} 

#alertname {
    width: 100%;
    height: 59px;

 }




 input#alertinput {
    width: 100%;
    height: 59px;
    font-size: 12px;
    outline-color: #216c98;
    border: 1px solid #ccc;
    padding: 10px;
    margin-left: 1px;
}
#alertlabel {
    color: #216c98;
    top: -20px;
    left: 12px;
    font-size: small;
    font-weight: bold;
    font-size: 12px;
    position: relative;

} 

 #alertdate {
    width: 100%;
    height: 59px;
    

} 
input#alertinput1 {
    width: 100%;
    height: 59px;
    font-size: 12px;
    outline-color: #216c98;
    border: 1px solid #ccc;
    padding: 10px; 
    z-index: 5;
    
}

#alertlist {
    width: 100%;
    height: 59px; 

}
 input#alertinput2 {
    width: 100%;
    height: 59px;
    font-size: 12px;
    outline-color: #216c98;
    border: 1px solid #ccc;
    padding: 10px;
} 

#alertlistitem {
    position: relative;
    top: -19px;
    list-style: none;
    padding: 10px;
    width: 100%;
    height: 75px;
    border: 1px solid #ccc;
    background: white;
    cursor: pointer;
    font-size: 12px;
    z-index: 1;

}

#alertlistitem li:hover {

    background-color: #045679;
    color: white;
}
#alertitems {
    padding: 8px;
    font-weight: lighter;
}
.caret-icon {
    color: #216c98;
    left: 164px;
    top: -38px;
    position: relative;
}
label#alertmsg {
    color: red;
    left: 12px;
    font-size: 8px;
    top: -22px;
    position: relative;
}

#buttonalert {
    padding: 6px;
    color: #0f6b93;
    border-radius: 24px;
    background-color: rgb(247, 247, 247);
    opacity: 0.5;
    border: 1.5px solid skyblue;
    font-size: 10px;
    font-weight: bold;
    cursor: not-allowed;
    position: relative;
    left: 380px;
    top: 200px;

}




#buttoncancel {
    padding: 6px;
    color: red;
    border-radius: 24px;
    background-color: rgb(247, 247, 247);
    position: relative;
    left: 375px;
    top: 200px;
    border: 1.5px solid red;
    font-size: 10px;
    font-weight: bold;
}

.highlighted {
    background-color: red;
}

#description{
    
    width: 100%;
    height: 100px;
    margin-top: 54px;
    margin-bottom: 30px;
    z-index: -1;
    position: relative;
}
.tox .tox-editor-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: hidden;
    /* z-index: -1; */
}

/* .react-datepicker-popper {
    z-index: 3;
} */
.tox-sidebar-wrap{
    position: absolute;
}
.tox :not(svg):not(rect) {
    background: 0 0;
    border: 0;
    box-shadow: none;
    float: none;
    height: auto;
    margin: 0;
    max-width: none;
    outline: 0;
    padding: 0;
    position: absolute;
    width: auto;
}

.react-datepicker__month-container{

    position: relative;
    z-index: 1000;
}