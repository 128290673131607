/* Styles for the flex container */

.flex-container_register {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Styles for the container */

.container_register {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Styles for headings */

.container_register h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Styles for form labels */

.container_register label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

/* Styles for input fields */

.container_register input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

/* Styles for the checkbox and its label */

.container_register span {
  display: block;
  margin-top: 15px;
  font-size: 14px;
  text-align: left;
}

/* Styles for the button */

#send_register {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

/* Disabled button style */

#send_register:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

#passVisible {
  cursor: pointer;
  color: #333;
  position: relative;
  float: right;
  top: -40px;
  margin-right: 10px;
  border: none;
  background-color: #f5f5f5;
}

b#notMatch {
  display: inline;
  color: blue;
  font-size: 7px;
}