/* ------------------Help icon---------------------------- */
.helpbox {
  position: fixed;
  right: 0px;
  bottom: 10px;
  border: 1.5px solid black;
  border-radius: 10%;
  cursor: pointer;
  transition: all 0.3s ease-out;
  z-index: 3;
}

.inbox input[type="text"] {
  height: 20px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: larger;
}

.help-input {
  height: 59px;
  width: 100%;
  padding: 5px;
  font-size: 12px;
}

#nameInput,#EmailInput{
  width:100%;
  height: 34px;
  padding-left: 5px;
  border: 0.1px solid rgb(56, 54, 54);
  border-radius: 3px;
}


/* --------------------helper---------------------- */
.overlay {
  position: fixed;
  top: -2px;
  bottom: 0;
  right: 0;
  left: 49.5%;
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.wrapper{
  margin: 39px 0px 0px 38px;
  padding: 10px;
  background: #fafbfb;
  border: 1.5px solid rgb(122, 118, 118);
  border-radius: 4px;
  width: 597px;
  height: 532px;
  position: absolute;
  transition: all 1s ease-in-out;
  overflow-y: auto;

}

.wrapper .head {
  padding-top: 7px;
  background-color: #3d87a6;
  border-radius: 2px;
  height: 35px;

  
}
.head{
  
  display: flex;
  justify-content:center;
}

.wrapper #p {
  margin-top: 0;
  color: rgb(252, 249, 249);
  /* background-color: #3d87a6; */
  align-content: center;
  padding-left: 95px;
  font-size: large;
  font-weight: bold;
  font-size: 16px;
}

#handleSubmit{

  margin-left: 130px;
 
}

.wrapper .close {
  position: absolute;
  top: 14px;
  right: 20px;
  transition: all 200ms;
  font-size: 19px;
  font-weight: 100;
  text-decoration: none;
  color: rgb(254, 254, 254);
}

.wrapper .content {
  max-height: 300%;
  overflow: inherit;
}

#successMessage{
  width: 100%;
  height: 100%;
  
}
#sucessMsg{
  height: 100%;
  width: 100%;
  border:0.1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'robot';
  font-family: sans-serif;
  font-weight: bold;
  color: #2f2e2e;
  font-size: 10px;
  color: rgb(252, 250, 250);
  background-color:#4785a0;
}

form label {
  font-family: 'robot';
  font-family: sans-serif;
  font-weight: bold;
  font-size: 12px;
  position: relative;
  color: #2f2e2e;
}

 .help input[type=text]  {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: both;
  /* background-color:#f4f5f7; */
}

input[type="submit"] {
  background-color: #4785a0;
  color: #fff;
  padding: 11px 30px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 15px;
  margin-left: 220px;
}

#format,#helpDropdown,#prefereddropdown,#yesOrNo{
  width: 100%;
  height: 35px;
  background-color: #f4f5f7;
  border-radius: 5px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 12px;
background-color: white;
border-radius: 3px;
}
#dateInput{
  width:597px;
  height: 35px;
  background-color: #f4f5f7;
  border-radius: 5px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 12px;
  border:0.1px solid rgb(87, 81, 81);
background-color: white;
border-radius: 3px;
padding: 5px;
}



.em {
  width: 100%;
  height: 30px;
}

#format option{
  width:200px;
}

#labels{
 padding-left:4px;
 font-size:13px;
}

#phoneEmail,#yesorNoDiv{
  margin-left: 12px;
  display: flex;
  align-items: center;
}

#levellistitems {
  padding: 4px;
  font-size: 11px;
}

#levellist li:hover {
  background-color: #102020;
  color: white;
}

#levelinput {
  width: 100%;
  height: 35px;
  border: 1px solid #ccc;
  padding-left: 12px;
  font-size: 11px;
  font-weight: bold;

}


#levellist {
  position: relative;
 margin-top: 17px;
  list-style: none;
  padding: 10px;
  width: 100%;
  height: 160px;
  border: 1px solid #ccc;
  background: white;
  cursor: pointer;
  font-size: 12px;
  z-index: 1;

}

#levelhead{
  font-size: 12px;
  font-weight: 700;
  }

  #acessinfodiv{
    color: #102020;
    font-size: 13px;
    font-weight: 500;
    
  }

  #okButton{
    width:100px;
    height:24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:4px;
    color:#3d87a6;
    margin: 10px;
    border:none;
  }

 /* prefered Time CSS */

 
#helpTimelistitems{
  padding: 4px;
  font-size: 11px;
  color: #2f2e2e;
}

#HelpTimelist li:hover {
  background-color: #045679;
  color: white;
}

#helpTimeinput {
  width: 100%;
  height: 43px;
  border: 1px solid #ccc;
   padding-left: 12px;
  font-size: 12px;
  font-weight: bold;
  border: 0.1px solid rgb(52, 49, 49);
  border-radius: 4px;


}


#helpTimelist {
  position: relative;
 margin-top: 18px;
  list-style: none;
  padding: 10px;
  width: 100%;
  height: 2739px;
  border: 1px solid #ccc;
  background: white;
  cursor: pointer;
  font-size: 12px;
  z-index: 1;
  color: black;

}

textarea{
  padding: 8px;
}




