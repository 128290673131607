*{
    margin: 0px;
    padding: 0px;
}


#container{
    text-align: center;
    margin-top: 191px;
    width: 23%;
    margin-left: 549px;
    height: 231px;
    position: fixed;
}

#setpassword{
    display: flex;
    flex-direction: column;
    height: 375px;
    padding-left: 15px;
}

#savepassword{
    width: 27%;
    height: 30px;
    align-self: center;
    border-radius: 11px;
    border-color: #2dbba7;
    color: #2dbba7;
    margin-right: 5px;
    margin-top: 16px;
}

#pass{
    width: 292px;
    height: 37px;
    border: none;
    outline: none;
    background: none;
    border-bottom: 1px solid #2dbba7;
}

#cpass{
    width: 292px;
    height: 37px;
    border: none;
    outline: none;
    background: none;
    border-bottom: 1px solid #2dbba7;
}


