.containerhome {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.main-contenthome {
    flex: 1;
    box-sizing: border-box;
    transition: margin-right 0.3s ease-in-out;
    margin-right: 0;
    width: 100%;
    margin-left: 70px;
}
.sidebarhome {
    position: absolute;
    top: 68px;
    right: -250px;
    width: 250px;
    height: 100%;
    background-color: #f2f2f2;
    transition: right 0.3s ease-in-out;
}

.sidebarhome-open .main-contenthome {
    margin-right: 250px;
}

.sidebarhome-open .sidebarhome {
    right: 0;
}

.sidebarhome-toggle {
    position: absolute;
    top: 280px;
    right: 0px;
    z-index: 1;
    height: 60px;
    width: 15px;
    transition: right 0.3s ease-in-out;
    background-color:#3d87a6;
    border-right: none;
    border-top-left-radius:6px;
    border-bottom-left-radius:6px;
    font-weight: bold;
    color: aliceblue;
}

.sidebarhome-open .sidebarhome-toggle {
    right: 210px;
}



@media screen and (max-width: 768px) {
    .sidebarhome-toggle {
        position: fixed;

    }

    .sidebarhome-open .sidebarhome-toggle {
        right: calc(100vw - 50px);
    }
}

#grid {
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: 90vh;
    background-color: white;
    margin-top: 67px;
}

#grid>div {
    font-size: 4vw;
    color: white;
    text-align: center;
}

.red {

    grid-column: auto;
    grid-row: auto;
    padding-right: 5px;
}

#box-1 {
    width: 100%;
    height: 100%;
    resize: none;
    border-radius: 4px;
    overflow-y: scroll;
    outline: none;
    border: none;
    margin: 0px;
}

#box-2 {
    width: 100%;
    height: 100%;
    resize: none;
    border-radius: 4px;
    overflow-y: scroll;
    outline: none;
    border: none;
    margin: 0px;
}

#box-3 {
    width: 100%;
    height: 100%;
    resize: none;
    border-radius: 4px;
    overflow-y: scroll;
    outline: none;
    border: none;
    margin: 0px;
}

#box-4 {
    width: 100%;
    height: 100%;
    resize: none;
    border-radius: 4px;
    overflow-y: scroll;
    outline: none;
    border: none;
    margin: 0px;
}

.green {
    grid-column: auto;
    grid-row: auto;
    padding-right: 5px;
}

.blue {
    grid-column: auto;
    grid-row: auto;
    padding-right: 5px;
    padding-top: 21px;
}

.gray {
    grid-column: auto;
    grid-row: auto;
    padding-right: 5px;
    padding-top: 21px;
}

.textData {
    height: 90%;
    border: 1px solid rgb(228, 227, 227);


}

.btn {
    background-color: rgb(213, 227, 231);
    width: 100%;
    border-radius: 1%;
    height: 40px;
    display: flex;
    overflow: hidden;
    border: #1e1e1f;
    align-items: center;
    padding-left: 140px;
    padding-bottom: 34px;
}

.bt{
    width: 140px;
    height: 30px;
    border: 1px solid black;
    outline: none;
    background-color: #fff;
    font-weight: bold;
    font-size: 10px;
    border-radius:2px;  
   }

   .btn2{
    width: 160px;
    height: 29px;
    border: 1px solid black;
    outline: none;
    background-color: #fff;
    font-weight: bold;
    font-size: 10px;
    border-radius:2px;
    align-content: center;
    margin-left:55%;
    background-color:#3d87a6;
    color: #e7eaea;
     
   }

   .bts{
    width: 160px;
    height: 30px;
    border: 1px solid black;
    background-color: #fff;
    font-weight: bold;
    font-size: 10px;
    border-radius:2px;
   }

   .activeH,bts:hover{
    background-color:#3d87a6;
    color: #e7eaea;
    
   }
    .activ,bts:hover{
      background-color:#3d87a6;
      color:#e7eaea;
   }

   .container-fluid{
    padding-left: 6%;
    padding-top: 68px;
   }