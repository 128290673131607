body {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .rowcontent {
    display: flex;
    padding-left: 66px;
    padding-top: 65px;
    background-color: white;
  }
  

  
  .plusiconcontent {
    padding: 15px;
    position: relative;
    margin-left: 100px;
    margin-top: 38%;
    position: fixed;
  }
  .plusiconcontent a {
    color: #0f6b93;
    text-decoration: none;
  }
  
  .rightcontent {
    flex: 75%;
    padding-left: 3px;
    margin-left: 21%;
    background-color: white;
    padding-right: 3px;
  
  }
 
  #headercontent {
    padding-top: 15px;
    margin-top: 2px;
    height: 48px;
    width: 100%;
    background: rgb(231, 231, 231);
    text-align: center;
    font-weight: bold;
    color: rgb(9, 9, 9);
    border: 1px solid rgb(83, 83, 83);
    border-radius: 1px;
  }
  
  .containercontent1 {
    margin-top: 10px;
    display: grid;
    height: 60px;
    grid-template-columns: 1fr 0.5fr 0.5fr 0.75fr 0.25fr;
  }
  .locklabel{
    display: grid;
    justify-content: center;
    color: #216c98;
    font-weight: bold;
    font-size: 11px;
    position: relative;
   
  }
  
  #cc5 {
    height: 60px;
    border-top: 0.1px solid rgb(218, 217, 217);
    border-right: 0.1px solid rgb(218, 217, 217);
    border-bottom: 0.1px solid rgb(218, 217, 217);
  }
  
  #cc1 input[type="text"],
  #cc3 input[type="number"],
  #cc4 input[type="text"] {
    padding-left: 6px;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 1px;
    height: 60px;
    width: 100%;
    font-size: medium;
    font-weight: lighter;
    font-size: 14px;
    outline-color: #216c98;
  }
  
  .Lockinput{
    padding-left: 6px;
    border: 0.1px solid rgb(218, 217, 217);
    height: 59px;
    font-size: 12px;
    outline-color: #216c98;
    width: 100%;
  }
  
  #cc3,
  input[type="number"]::-webkit-outer-spin-button,
  #cc3,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  #cc2 select {
    padding-left: 7px;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 1px;
    height: 60px;
    width: 100%;
    font-size: medium;
    font-weight: lighter;
    font-size: 14px;
    outline-color: #216c98;
    background-color: white;
  }
  

  #toolbar {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: rgb(222, 222, 222);
    border-bottom: 1px solid rgb(13, 13, 13);
  }
  
  #toolbar button {
    padding: 4px 8px;
    font-size: 16px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  

  .saveandcancelcontent {
    margin-top: 3%;
    margin-left: 40%;
    height: 100px;
  }
  
  #resetcontent {
    width: 70px;
    padding: 8px;
    color: maroon;
    border-radius: 20px;
    border-color: red;
    font-size: 11px;
    font-weight: bold;
    right: 10px;
    position: relative;
  }
  
  #savecontent {
    width: 110px;
    padding: 8px;
    color: green;
    border-radius: 20px;
    border-color: green;
    font-size: 11px;
    font-weight: bold;
  }
  
  #color-picker {
    width: 20px;
    border: none;
    margin-left: 5px;
    background-color:rgb(222, 222, 222) ;
  }
  div#editableContent {
    height: 100px;
}

.iputcontent{
  padding-left: 6px;
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: -15px;
  height: 60px;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
  font-size: 14px;
  outline-color: #216c98;
}

.dropcontent {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 1px;
  list-style: none;
  top: -18px;
  z-index: 3;
  height: 50%;
  overflow-y: scroll;
}
.dropcontent li:hover {
  background-color: #0f6b93;
  color: white;
}

.dropcontent li {
  cursor: pointer;
  padding: 5px;
  font-size: 10px;
  font-weight: bold;

}

#togglecontent {
  color: #0f6b93;
  position: relative;
  float: right;
  top: -37px;
  right: 22px;
}
.labelcontent{
  color: #216c98;
  top: -20px;
  left: 12px;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}
.ctaloginput{
  padding-left: 6px;
  border: 0.1px solid rgb(218, 217, 217);
  height: 60px;
  font-size: 12px;
  outline-color: #216c98;
  width: 100%;
}

.titleinput::placeholder,
.iputcontent::placeholder,
.ctaloginput::placeholder, 
.catalogcatainput::placeholder, 
.Lockinput::placeholder 
{
  font-style: italic;
  color: #ccc;
  padding: 6px;
  font-size: 14px;
}
#nodtapluscontent{
  position: relative;
  font-size: 12px;
  text-align: center;
  top: 10px;
  font-weight: bold;
  color: lightgray;
}

#maincontent {
  transition: margin-right .5s;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 5px;
  position: relative;
  z-index: 1;
  display: none;
}

#iconcontent{
  font-weight: bold;
  font-size: 12px;
}
input.Lockinputcheckbox {
  display: grid;
  justify-content: center;
  position: relative;
  left: 27px;
  height: 20px;
  width: 20px;
  margin-top: 10px;
}
.titlelabel , .catnumlabel , .catcatlabel{
    color: #216c98;
    top: -20px;
    left: 10px;
    font-size: small;
    font-weight: bold;
    font-size: 11px;
    position: relative;
    }
    #cacacontent {
      color: #0f6b93;
      position: relative;
      float: right;
      top: -40px;
      right: 22px;
  
  }
  .ulrolecontent {
    z-index: 3;
    position: relative;
    background-color: #ffffff;
    border: 1px solid #ccc;
    margin-top: -20px;
    list-style: none;

}

.ulrolecontent li:hover {
    background-color: #0f6b93;
    color: white;
}

.ulrolecontent li {
    cursor: pointer;
    padding: 5px;
    font-size: 10px;
    font-weight: bold;

}
  



  .requillgridcontent {
    position: relative;
    z-index: 1;
}
