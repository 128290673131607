body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
}

.navandbread1 {
    position: fixed;
    width: 100%;
    top: 0%;
    z-index: 2;
}

.myprofile {
    top: 70px;
    position: relative;
}

#myprofileheader {
    margin-left: 70px;
    margin-right: 2px;
    height: 50px;
    padding-top: 15px;
    background: rgb(231, 231, 231);
    text-align: center;
    font-weight: bold;
    color: rgb(9, 9, 9);
    border: 1px solid rgb(83, 83, 83);
    border-radius: 1px;
}

.left-grid-item1 i {
    margin-top: 25px;
    margin-left: 35%;
    color: rgb(215, 213, 213);
    font-size: 95px;
    margin-bottom: 20px;
}

.role b {
    margin-left: 50%;
}

.roles i {
    margin-left: 40%;
    color: rgb(122, 121, 121);
    font-size: 13px;
}

#myLogin {
    display: none;
}

.reauthenticate {
    margin-left: 25%;
    padding: 8px;
    color: rgb(71, 196, 246);
    border-radius: 24px;
    background-color: rgb(247, 247, 247);
    text-decoration: none;
    border: 2px solid skyblue;
    font-size: 10px;
    font-weight: bold;
}

.preferences b {
    margin-left: 47%;
}

.loginemail {
    margin-left: 43%;
    margin-bottom: 30px;
}

.grid-containermy {
    display: flex;
    grid-template-columns: auto auto;
}

.containerP1 {
    display: grid;
    height: 59px;
    grid-template-columns: 1fr 1fr;
    margin-right: 10px;
    float: right;
    width: 100%;
}

#contentP1 label {
    color: #216c98;
    top: -20px;
    left: 5px;
    font-size: small;
    font-size: 11px;

}

.containerP2 {
    padding-top: -5px;
    display: grid;
    height: 59px;
    grid-template-columns: 1fr 1fr;
    margin-right: 10px;
    float: right;
    width: 100%;
}

#contentP2 label {
    color: #216c98;
    top: -20px;
    left: 5px;
    font-size: small;
    font-size: 11px;
}

.containerP3 {
    display: grid;
    height: 59px;
    grid-template-columns: 1fr 1fr;
    margin-right: 10px;
    float: right;
    width: 100%;
    margin-bottom: 30px;
}

#contentP3 label {
    color: #216c98;
    top: -20px;
    left: 5px;
    font-size: small;
    font-size: 11px;
}

.containerP4 {
    padding-left: 78px;
    padding-top: -2px;
    display: grid;
    height: 59px;
    grid-template-columns: 1fr 1fr;
    margin-right: 10px;
    float: right;
    width: 100%;
}

#contentP4 label {
    color: #216c98;
    top: -20px;
    left: 5px;
    font-size: small;
    font-size: 11px;
}

#contentP4A {
    border-top: 0.1px solid rgb(218, 217, 217);
    border-left: 0.1px solid rgb(218, 217, 217);
    border-right: 0.1px solid rgb(218, 217, 217);
}

#contentP4B {
    border-top: 0.1px solid rgb(218, 217, 217);
    border-left: 0.1px solid rgb(218, 217, 217);
    border-right: 0.1px solid rgb(218, 217, 217);
    padding-top: 17px;
    padding-left: 30px;
}

#contentP4A label {
    color: #216c98;
    top: 40px;
    left: -12px;
    font-size: small;
    font-size: 11px;
}


.containerP5 {
    padding-left: 78px;
    display: grid;
    height: 59px;
    grid-template-columns: 1fr 1fr;
    margin-right: 10px;
    float: right;
    width: 100%;
    margin-bottom: 10px;
}

#contentP5 label {
    color: #216c98;
    top: -20px;
    left: 5px;
    font-size: small;
    font-size: 11px;
}

.containerP6 {
    margin-top: 10px;
    padding-left: 78px;
    display: grid;
    height: 59px;
    grid-template-columns: 1fr 1fr;
    margin-right: 10px;
    float: right;
    width: 100%;
}

.containerP6 select,
.containerP7 select {
    padding-left: 7px;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 1px;
    height: 59px;
    width: 100%;
    font-size: medium;
    font-weight: lighter;
    font-size: 14px;
    outline-color: #216c98;
    background-color: white;
}

#contentP6 label {
    color: #216c98;
    top: -20px;
    left: 5px;
    font-size: small;
    font-size: 11px;
}

.containerP7 {
    padding-left: 78px;
    display: grid;
    height: 60px;
    grid-template-columns: 1fr 1fr;
    margin-right: 10px;
    float: right;
    width: 100%;
    margin-bottom: 20px;
}

#contentP7 label {
    color: #216c98;
    top: -20px;
    left: 5px;
    font-size: small;
    font-size: 11px;
}

.containerP7 select {
    text-transform: uppercase;
}

.delete1 {
    margin-top: 20px;
    padding: 8px;
    color: rgb(190, 21, 21);
    border-radius: 24px;
    background-color: rgb(247, 247, 247);
    text-decoration: none;
    border: 2px solid rgb(242, 7, 7);
    font-size: 11px;
    margin-left: 30%;
    font-weight: bold;
}

#edit {
    margin-left: 50%;
    padding: 8px;
    color: rgb(71, 196, 246);
    border-radius: 24px;
    background-color: rgb(247, 247, 247);
    text-decoration: none;
    border: 2px solid rgb(12, 132, 180);
    font-size: 12px;
    font-weight: bold;
}

#contentP5 input[type="text"],
.right-grid-item2 input[type="text"] {
    padding-left: 7px;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 1px;
    height: 59px;
    width: 100%;
    font-size: medium;
    font-weight: lighter;
    font-size: 14px;
    outline-color: #216c98;
}

.right-grid-item2 input[type="email"] {
    padding-left: 7px;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 1px;
    height: 59px;
    width: 100%;
    font-size: medium;
    font-weight: lighter;
    font-size: 14px;
    outline-color: #216c98;
}

.right-grid-item2 input[type="checkbox"] {
    padding-left: 7px;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 1px;
    height: 59px;
    width: 100%;
    font-size: medium;
    font-weight: lighter;
    font-size: 14px;
    outline-color: #216c98;
}

.left-grid-item1 {
    left: 40%;
    width: 50%;
}

.right-grid-item2 {
    right: 60%;
    width: 100%;
}

.loginemailpage {
    margin-top: 75px;
    margin-left: 68px;
    height: 360px;
    width: 94%;
    background-color: gainsboro;
    margin-bottom: 40px;
    border: 1px solid gray;
    bottom: 30px;
}

.loginemailpage b {
    margin-top: 15px;
    margin-left: 40%;
    font-weight: lighter;
    display: flex;
}

.loginright {
    margin-top: 20px;
    height: 230px;
    width: 48%;
    float: right;
}

.loginleft {
    margin-top: 20px;
    margin-left: 8px;
    width: 46%;
    float: left;
    height: 230px;
    background-color: white;
}

.containerL1 {
    width: 100%;
}

.loginleft ::placeholder {
    padding-left: 6px;

}

#contentL1,
#contentL2,
#contentL3,
#contentL4 {
    height: 59px;
}

#contentL1label,
#contentL2label,
#contentL3label,
#contentL4label {
    color: #216c98;
    top: -20px;
    left: 5px;
    font-size: small;
    font-size: 11px;
}

#contentL1 input[type="email"],
#contentL2 input[type="password"],
#contentL3 input[type="password"],
#contentL4 input[type="password"] {
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 1px;
    height: 59px;
    width: 100%;
    font-size: medium;
    font-weight: lighter;
    font-size: 14px;
    outline-color: #216c98;
}

.saveandcance2 {
    margin-top: 22%;
    height: 100px;
    margin-left: 43%;
}

#reset2 {
    width: 70px;
    padding: 8px;
    color: maroon;
    border-radius: 21px;
    border-color: red;
    font-size: 11px;
}

#save2 {
    width: 70px;
    padding: 8px;
    color: green;
    border-radius: 20px;
    border-color: green;
    font-size: 11px;
}