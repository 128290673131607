.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}



main {
  width: 100%;
}

.sidebarfix {
  margin-top: 65px;
  height: 100%;
  position: fixed;
  z-index: 10;
}

.sidebar {
  background: #045679;
  color: black;
  height: 660px;
  width: 67px;
}

.top_section {
  display: flex;
  padding: 20px 20px;
}

.link {
  display: flex;
  color: #fff;
  padding: 8px 17px;
  border-bottom: 1px solid white;
}

.link:hover {
  background: lightskyblue;
  color: #000;
  transition: all 0.2s;
}

.active {
  background: white;
  color: #000;
}

.icon {
  font-size: 20px;
}

.link_text {
  font-size: 8px;
  font-family: 'robot';
  text-align: center;
}

/* --------------------top navigation bar(black)------------------------------- */

nav {
  display: flex;
  width: 100%;
  background: rgb(41, 41, 44);
  justify-content: space-between;
  padding: 6px 0px;

}

.navset a i {
  font-size: 20px;
}

.navandbread {
  position: fixed;
  width: 100%;
  z-index: 4;
}

/* ------------------searchbar--------------------- */
.search {
  left: 10px;
  position: relative;
  width: 30px;
  height: 23px;
  background: rgb(241, 241, 245);
  border-radius: 90px;
  transition: 0.5s;
  box-shadow: 0 0 0 1px rgb(41, 41, 44);
  overflow: hidden;
}

.search.active {
  width: 250px;
}

.search .icon {
  position: absolute;
  top: 0;
  left: -12px;
  width: 60px;
  height: 28px;
  background: rgb(242, 240, 240);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

/* .search .icon::before {
  display: none;
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2.5px solid black;
  border-radius: 50%;
  transform: translate(-4.5px, -4.5px);
} */

/* .search .icon::after {
  display: none;
  content: '';
  position: absolute;
  width: 2px;
  height: 10px;
  background: black;
  color: black;
  border-radius: 40%;
  transform: translate(3px, 3px) rotate(315deg);
} */
i#fasearchicon {
  font-size: 15px;
  margin-right: 5px;
  margin-bottom: 3px;
}

.search .input {
  position: relative;
  width: 200px;
  height: 60px;
  left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#mysearch {
  position: relative;
  bottom: 18px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 13px;
  padding: 10px 0;
}

.clear {
  position: absolute;
  top: 20%;
  transform: translate(-60%);
  width: 15px;
  height: 15px;
  right: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clear::before {
  position: absolute;
  content: '';
  width: 1px;
  height: 10px;
  background: #999;
  transform: rotate(45deg);
}

.clear::after {
  position: absolute;
  content: '';
  width: 1px;
  height: 10px;
  background: #999;
  transform: rotate(315deg);
}

/* -------------------breadcrumbs---------------------- */
.bread {
  position: fixed;
  width: 100%;
  z-index: 1;
  height: 30px;
  z-index: 3;
  margin-top: 35px;
}

.breadcrumbs {
  padding-bottom: 6px;
  padding-left: 4px;
  font-family: sans-serif;
  font-size: x-small;
  background-color: white;
  padding-top: 9px;
}

.breadcrumbs--item {
  display: inline-block;
  text-transform: uppercase;
}

.breadcrumbs--item:not(:last-of-type)::after {
  content: '\203a';
  content-visibility: visible;
  margin: 0 10px;
  color: #a39494;
}

.breadcrumbs--link {
  text-decoration: none;
  color: #999999;
}

.breadcrumbs--link--active {
  color: #1b2a27;
  font-size: 12px;
  font-weight: 500;
  font-weight: bold;
}

.breadcrumbs i:hover {
  color: #216c98;
}

.hr {
  border: none;
  height: 1px;
  background: #8a8787;
}

/* ---------------profiledropdown---------------- */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 29px;
  right: 0px;

}

.dropdown-content a {
  color: black;
  padding: 16px 20px;
  font-family: sans-serif;
  font-size: x-small;
  font-size: 80%;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid rgb(11, 11, 11);
}

.dropdown a:hover {
  background-color: #045679;
  color: white;
}

.show {
  display: block;
}

body {
  background-color: #fff;
  background-size: cover;
  background-position: center;
  font-family: monospace;
  height: 100vh;
  overflow-x: hidden;

}