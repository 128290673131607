body {
  font-family: Arial, Helvetica, sans-serif;
}

#config-head {
  /* margin-top: 5px; */
  padding-top: 82px;
  height: 120px;
  width: 101%;
  background: rgb(231, 231, 231);
  text-align: center;
  font-weight: bold;
  color: rgb(9, 9, 9);
  border: 1px solid rgb(83, 83, 83);
  border-radius: 1px;
}

.fa-angle-down {
  font-size: 12px;
  font-weight: bolder;
  color: #0c0c0c;
}

.config-number {
  color: #045679;
  font-weight: bold;
}

.config-name {
  font-weight: bold;
}

#mainconfig {
  margin-top: 5px;
  margin-left: 60px;
  margin-right: 20px;
}

#subsection1 {
  display: grid;
  grid-template-columns: 0.7fr 8fr 4fr;
  border: 1px solid #e6e4e4;
  height: 35px;
  width: 100%;
}

.subconfig-number {
  margin-top: 7px;
  text-align: center;
  color: #045679;
  font-weight: bold;
  font-size: 15px;
}

.subconfig-name {
  padding-top: 8px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #012433;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  height: 100%;
  position: relative;
}

#select-1 {
  border: none;
}



/* --------------------1------------------- */
.con-sec1 {
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 13fr;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px;
  border: 1px solid #ccc;
  height: 35px;
  width: 100%;
  margin-left: 10px;
}

.con-sec1:hover {
  background-color: rgb(231, 231, 231);
}

#section1 {
  border: 1px solid #ccc;
  width: 100%;
  margin-left: 10px;
}

/* --------------2--------------- */
.con-sec2 {
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 13fr;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px;
  border: 1px solid #ccc;
  height: 35px;
  width: 100%;
  margin-left: 10px;
}

.con-sec2:hover {
  background-color: rgb(231, 231, 231);
}

#section2 {
  border: 1px solid #ccc;
  width: 100%;
  margin-left: 10px;
}

/* --------------3---------------- */
.con-sec3 {
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 13fr;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px;
  border: 1px solid #ccc;
  height: 35px;
  width: 100%;
  margin-left: 10px;
}

.con-sec3:hover {
  background-color: rgb(231, 231, 231);
}

#section3 {
  border: 1px solid #ccc;
  width: 100%;
  margin-left: 10px;
}

/* ---------------4--------------- */
.con-sec4 {
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 13fr;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px;
  border: 1px solid #ccc;
  height: 35px;
  width: 100%;
  margin-left: 10px;
}

.con-sec4:hover {
  background-color: rgb(231, 231, 231);
}

#section4 {
  border: 1px solid #ccc;
  width: 100%;
  margin-left: 10px;
}

/* -------------------5------------------ */
.con-sec5 {
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 13fr;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px;
  border: 1px solid #ccc;
  height: 35px;
  width: 100%;
  margin-left: 10px;
}

.con-sec5:hover {
  background-color: rgb(231, 231, 231);
}

#section5 {
  border: 1px solid #ccc;
  width: 100%;
  margin-left: 10px;
}

/* ----------------6------------------- */
.con-sec6 {
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 13fr;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px;
  border: 1px solid #ccc;
  height: 35px;
  width: 100%;
  margin-left: 10px;
}

.con-sec6:hover {
  background-color: rgb(231, 231, 231);
}

#section6 {
  border: 1px solid #ccc;
  width: 100%;
  margin-left: 10px;
}

/* ---------------7------------------- */
.con-sec7 {
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 13fr;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px;
  border: 1px solid #ccc;
  height: 35px;
  width: 100%;
  margin-left: 10px;
}

.con-sec7:hover {
  background-color: rgb(231, 231, 231);
}

#section7 {
  border: 1px solid #ccc;
  width: 100%;
  margin-left: 10px;
}

/* -------------------8---------------- */
.con-sec8 {
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 13fr;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px;
  border: 1px solid #ccc;
  height: 35px;
  width: 100%;
  margin-left: 10px;
}

.con-sec8:hover {
  background-color: rgb(231, 231, 231);
}

#section8 {
  border: 1px solid #ccc;
  width: 100%;
  margin-left: 10px;
}

/* ------------------9------------------- */
.con-sec9 {
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 13fr;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px;
  border: 1px solid #ccc;
  height: 35px;
  width: 100%;
  margin-left: 10px;
}

.con-sec9:hover {
  background-color: rgb(231, 231, 231);
}

#section9 {
  border: 1px solid #ccc;
  width: 100%;
  margin-left: 10px;
}

/* --------------10------------------ */
.con-sec10 {
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 13fr;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px;
  border: 1px solid #ccc;
  height: 35px;
  width: 100%;
  margin-left: 10px;
}

.con-sec10:hover {
  background-color: rgb(231, 231, 231);
}

#section10 {
  border: 1px solid #ccc;
  width: 100%;
  margin-left: 10px;
}

/* ----------------------------------- */
.input-select-wrapper {
  position: relative;
}

#input-select,
#input-select-2,
#input-select-3,
#input-select-4,
#input-select-5,
#input-select-6,
#input-select-7,
#input-select-8,
#input-select-9,
#input-select-10,
#input-select-11,
#input-select-12,
#input-select-13,
#input-select-14,
#input-select-15,
#input-select-16,
#input-select-17,
#input-select-18,
#input-select-19,
#input-select-20,
#input-select-21,
#input-select-22,
#input-select-23,
#input-select-24,
#input-select-25,
#input-select-26,
#input-select-27,
#input-select-28,
#input-select-29,
#input-select-30,
#input-select-31,
#input-select-32,
#input-select-33,
#input-select-34,
#input-select-35,
#input-select-36,
#input-select-37,
#input-select-38,
#input-select-39,
#input-select-40,
#input-select-41,
#input-select-42,
#input-select-43,
#input-select-44,
#input-select-45,
#input-select-46,
#input-select-47,
#input-select-48,
#input-select-49,
#input-select-50,
#input-select-51,
#input-select-52,
#input-select-53,
#input-select-54,
#input-select-55,
#input-select-56,
#input-select-57,
#input-select-58,
#input-select-59,
#input-select-60,
#input-select-61,
#input-select-62,
#input-select-63,
#input-select-64,
#input-select-65,
#input-select-66,
#input-select-67,
#input-select-68,
#input-select-69,
#input-select-70,
#input-select-71,
#input-select-72,
#input-select-73,
#input-select-74,
#input-select-75,
#input-select-76,
#input-select-77,
#input-select-78,
#input-select-79,
#input-select-80,
#input-select-81,
#input-select-82,
#input-select-83,
#input-select-84,
#input-select-85,
#input-select-86,
#input-select-87,
#input-select-88,
#input-select-89,
#input-select-90,
#input-select-91,
#input-select-92,
#input-select-93,
#input-select-94,
#input-select-95,
#input-select-96


{
  width: 100%;
  height: 31px;
  border: none;
  outline-color: #045679;
  border-radius: 0;
  text-align: center;
  font-weight: bold;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}
.css-b62m3t-container{
outline: #0c0c0c;
}

.input-select-options {
  position: absolute;
  top: 34px;
  left: 0;
  z-index: 1;
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  font-weight: bold;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}
.input-select-options li {
  padding: 7px 10px;
  cursor: pointer;
}
.input-select-options li:hover {
  background-color: #ccc;
}



.input-select-options-over{
  position: absolute;
  top: 34px;
  left: 0;
  z-index: 1;
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  font-weight: bold;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  overflow-y: scroll;
  height: 180px;
}

.input-select-options-over li {
  padding: 7px 10px;
  cursor: pointer;
}

.input-select-options-over li:hover {
  background-color: #ccc;
}
#password-input {
  padding-right: 30px;
  width: 100%;
  height: 32px;
  border: none; 
  outline-color: #045679;/* add some padding to the right of the input field for the icon */
  border-radius: 0;
  text-align: center;
  font-weight: bold;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

.toggle-password {
  position: absolute; /* position the button absolutely */
  top: 50%; /* move the button to the vertical center */
  right: 10px; /* position the button to the right of the input field */
  transform: translateY(-50%); /* adjust the vertical position of the button */
  border: none; /* remove the border of the button */
  background: transparent; /* remove the background of the button */
  cursor: pointer; /* change the cursor to a pointer */
}

.toggle-password:focus {
  outline: none; /* remove the focus outline */
}

.toggle-password i {
  font-size: 16px; /* adjust the size of the icon */
}

#psseye{
  color: #045679;
  padding-top: 4px;
  font-size: 10px;
}
.input-select-options {
  display: none;
  list-style: none;
  padding: 0;
}

.input-select-options li {
  cursor: pointer;
}

.input-select-wrapper.active .input-select-options {
  display: block;
}
.input-select-wrapper {
  position: relative;
}

.input-select-container {
  display: flex;
  align-items: center;
}

.input-select {
  padding-right: 30px; /* Adjust this value based on the width of the icon */
}

.toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust this value to position the icon properly */
  transform: translateY(-50%);
  cursor: pointer;
}

.toggle-icon:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 4px; /* Adjust the border-width to change the size of the icon */
  border-color: #888 transparent transparent transparent;
}

.angle-up:before {
  transform: rotate(-180deg);
}

.angle-down:before {
  transform: rotate(0);
}
#save-configurationbtn{
  height: 30px;
  width: 85px;
  color:#045679;
  border-radius: 20px;
  border-color: #045679;
  margin-left: 40%;
  margin-top: 3%;
}