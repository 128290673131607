body {
    font-family: Arial, Helvetica, sans-serif;
}

* {
    box-sizing: border-box;
}


.rowsecurity {
    display: flex;
    background-color: white;
    margin-left: 68px;
    padding-top: 67px;
}

.rightsecurity {
    flex: 75%;
    padding-left: 3px;
    margin-left: 21.5%;
    background-color: white;
    z-index: 1;
    padding-right: 4px;
}

.plusiconsecurity {
    padding: 15px;
    margin-top: 38%;
    margin-left: 90px;
    position: fixed;
    cursor: pointer;
    text-decoration: none;
}

#plusiconsecure {
    color: #0f6b93;
}

#securitytmsgdiv {
    position: relative;
    top: 12px;
    text-align: center;
}
 #securitytmsg
 {
    position: relative;
    font-size: 10px;
    text-align: center;
    font-weight: bold;
    color:#ccc;
    font-weight: bold;
}

#headersecurity {
    padding-top: 15px;
    height: 50px;
    width: 100%;
    background: rgb(231, 231, 231);
    text-align: center;
    font-weight: bold;
    color: rgb(9, 9, 9);
    border: 1px solid rgb(83, 83, 83);
    border-radius: 1px;
}

.securitycontentshow  {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
}

.class1 {
    position: relative;
    top: 60px;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
    border-bottom: 1px solid #ccc;
}
.class2 {
    text-align: center;
    font-size: 12px;
    color: #216c98;
    padding: 20px;
}

#label1 {
    position: relative;
    color: #216c98;
    top: 60px;
    font-size: 14px;
    margin-bottom: 15px;
    display: grid;
    text-align: center;
}

.classbutton {
    position: relative;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr ;
    margin: 150px 0 100px 0;
}

.classbuttondiv {
    padding: 5px;
    text-align: center;
    font-size: 12px;
    height: 30px;
    width: 146px;
    border-radius: 20px;  
}

#savebutton {
    color: rgb(21, 58, 3);
    border-color: green;  
}

#deletebutton{
    color: maroon;
    border-color: red;
}

.securityrolediv {
    padding-top: 7px;
    position: relative;
    display: grid;
    height: 60px;
    top: 17px;
    grid-template-columns: 0.5fr 0.5fr;
}

#securityinput {
    border: 1px solid #ccc;
    padding: 8px;
    outline-color: #216c98;
    width: 100%;
    height: 60px;
}

#securitylabel {
    position: absolute;
    top: 47px;
    left: 3px;
    padding: 5px;
    font-size: 11px;
    color: #216c98;
    font-weight: bold;
}

#roleinput {
    border: 1px solid #ccc;
    padding: 8px;
    outline-color: #216c98;
    width: 100%;
    height: 60px;
}

#reqmsgs {
    position: relative;
    top: -20px;
    font-size: 11px;
    left: 8px;
    font-weight: bold;
} 
#reqmsgrole{
    position: relative;
    top: -20px;
    font-size: 11px;
    left: 6px;
    font-weight: bold;
}

.reqmsglabel {
    color: red;
}

.rolelabel {
    color: #216c98;
}





.staticcontaionerheader {
    position: relative;
    top: 60px;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
    border-bottom: 1px solid #ccc;
    height: 40px;
}
