body {
    font-family: 'Courier New', Courier, monospace;
  }
  
  
  .topbar {
    position: fixed;
    width: 100%;
  }
  
  
  .flex-containerquote {
    width: 100%;
    height: 100vh;
  }
  
  .row-newquote {
    display: flex;
    padding-left: 66px;
    padding-top: 65px;
    background-color: white;
  }
  
  .left-newquote {
    flex: 27%;
    border: 2px solid #045679;
    width: 25%;
    height: 90vh;
    padding-left: 1px;
    position: fixed;
  
  }
  
  .right-newquote {
    flex: 70%;
    border-right: 1px solid black;
    margin-right: 15px;
  }
  
  .ugs {
    padding-left: 18px;
    padding-top: 10px;
    height: 50px;
    font-size: 20px;
    background-color: #045679;
    color: white;
  }
  
  .template {
    padding: 7px;
    border: none;
  }
  
  #temp {
    height: 75px;
    background-color: white;
    border-radius: 0%;
    border: 1px solid lightgray;
    border-left: 5px solid #045679;
    width: 100%;
    font-size: 17px;
    outline: none;
  }
  
  #temp:hover {
    border: none;
    outline: 2px solid #045679;
  }
  
  #dable,
  select:invalid {
    color: grey;
  }
  
  select:valid {
    color: black
  }
  
  #create {
    height: 30px;
    width: 80px;
    background: #045679;
    color: white;
    margin-top: 20px;
    border-radius: 20px;
    margin-left: -55px;
  }
  
  
  button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  #l7 {
    top: -45px;
    font-size: 11px;
    padding-left: 15px;
    color: #045679;
    position: relative;
    font-weight: bold;
  }
  
  
  .radiotop {
    border: 1px solid lightgrey;
    width: 60%;
    float: right;
  }
  
  #weeks {
    width: 17%;
    height: 50px;
    background: none;
    border-top: none;
    border-bottom: 2px solid #045679;
    border-left: none;
    border-right: 1px solid lightgray;
    outline: none;
    border-radius: 0;
  }
  
  .quotedetails {
    width: 60%;
    float: right;
    background: rgb(245, 244, 244);
  }
  
  #qdetails {
    width: 40%;
    background: none;
    border: none;
    outline: none;
    height: 60px;
    text-align: center;
    margin-left: 30px;
  }
  
  .product {
    background: white;
    border: none;
    outline: none;
    height: 30px;
    width: 60px;
    margin-left: 100px;
    margin-right: -115px;
  }
  
  #create_link{
    color: aliceblue;
      }