body {
  font-family: Arial, Helvetica, sans-serif;
}

.opportunities {
  padding-top: 80px;
}

.opportunities b {
  margin-left: 42%;
}

.opportunities a {
  text-decoration: none;
  color: rgb(15, 15, 15);
}

.opportunities table {
  color: black;
  font-size: 0.9em;
  font-weight: 300;
  line-height: 40px;
  border-collapse: separate;
  border-spacing: 0;
  border: 0.5px solid #1b1718;
  width: 100%;
  margin: 10px auto;
}

.opportunities th {
  background: #f5f4f4;
  color: #110f0f;
  border-bottom: 0.1px solid gray;
  border-left: 0.1px solid rgb(176, 172, 172);
}

.opportunities td {
  border-bottom: 0.1px solid gray;
}

.opportunities td:hover:not(th) {
  background-color: rgb(218, 217, 217);
}

.opportunities input[type="button"] {
  transition: all 0.3s;
  border: 1px solid #ddd;
  padding: 6px 12px;
  text-decoration: none;
  border-radius: 1px;
  font-size: 12px;
}

.opportunities input[type="button"]:not(.active) {
  background-color: transparent;
}

.opportunities .active {
  background-color: gray;
  color: #fff;
}

.opportunities input[type="button"]:hover:not(.active) {
  background-color: #ddd;
}

/* -----------+ icon opportunities------------- */
.plus-oppertunities {
  margin-top: 10px;
  margin-left: 48%;
}

.plus-oppertunities a {
  text-decoration: none;
  color: #216c98;
}

.oppo {
  padding-left: 66.5px;
  padding-top: 68px;
}

#opportunitiesheader {
  padding-top: 15px;
  margin-left: 5px;
  height: 50px;
  width: 99%;
  background: rgb(231, 231, 231);
  text-align: center;
  font-weight: bold;
  color: rgb(9, 9, 9);
  border: 1px solid rgb(83, 83, 83);
  border-radius: 1px;
}

.oppo input[type="text"] {
  padding-left: 7px;
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
  font-size: 13px;
  outline-color: #216c98;
}

.oppo input[type="number"] {
  padding-left: 7px;
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
  font-size: 14px;
  outline-color: #216c98;
}

.oppo input::-webkit-outer-spin-button,
.oppo input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.containerO1 {
  padding-top: 7px;
  display: grid;
  height: 60px;
  margin-right: 10px;
  margin-left: 5px;
}

#contentO1 {
  width: 100%;
}

#contentO1 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

.containerO2 {
  padding-top: 6px;
  display: grid;
  height: 60px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-right: 10px;
  margin-left: 5px;
}

#contentO2 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

.containerO3 {
  padding-top: 5px;
  display: grid;
  height: 60px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-right: 10px;
  margin-left: 5px;
}

/* ---opportunities custom dropdoen-------- */
#contentO3,
#contentO5,
#contentO52,
#contentO53,
#contentO6,
#contentO61,
#contentO62 {
  position: relative;
  height: 59px;
}

#contentO3 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

#content05 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

#contentD4PermissionType {
  position: relative;
  height: 59px;
}

#dropdownlistcontent03,
#dropdownlistcontent05,
#dropdownlistcontent052,
#dropdownlistcontent053,
#dropdownlistcontent06,
#dropdownlistcontent061,
#dropdownlistcontent062,
#dropdownlistcontentD4 {
  position: relative;
  margin-top: -1px;
  list-style: none;
  padding: 7px;
  width: 100%;
  max-height: 80px;
  border: 1px solid #ccc;
  background: white;
  cursor: pointer;
  font-size: 12px;
  z-index: 1;
  overflow-y: auto;
  top: 1px;
}

#contentO52 label {
  color: #216c98;
  top: -20px;
  left: 9px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

#contentO53 label {
  color: #216c98;
  top: -20px;
  left: 9px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

#dropdownlistcontent03 :hover {
  background-color: #216c98;
  color: white;
}

#dropdownlistcontent05 :hover {
  background-color: #216c98;
  color: white;
}

#dropdownlistcontent052 :hover {
  background-color: #216c98;
  color: white;
}

#dropdownlistcontent053 :hover {
  background-color: #216c98;
  color: white;
}

#dropdownlistcontent06 :hover {
  background-color: #216c98;
  color: white;
}

#dropdownlistcontent061 :hover {
  background-color: #216c98;
  color: white;
}

#dropdownlistcontent062 :hover {
  background-color: #216c98;
  color: white;
}

#dropdownlistcontentD4 :hover {
  background-color: #216c98;
  color: white;
}

.dropdown-list-item-content03,
.dropdown-list-item-content05,
.dropdown-list-item-content052,
.dropdown-list-item-content053,
.dropdown-list-item-content06,
.dropdown-list-item-content061,
.dropdown-list-item-content062,
.dropdown-list-item-PermissionType {
  cursor: pointer;
  padding: 4px;
}

.dropdown-icon-content03,
.dropdown-icon-content05,
.dropdown-icon-content052,
.dropdown-icon-content053,
.dropdown-icon-content06,
.dropdown-icon-content061,
.dropdown-icon-content062,
.dropdown-icon-PermissionType {
  position: absolute;
  font-size: 11px;
  color: #216c98;
  top: 33%;
  left: 94%;
}

/* .dropdown-icon-content062 */
.containerO4 {
  padding-top: 4px;
  display: grid;
  height: 60px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-right: 10px;
  margin-left: 5px;
  margin-bottom: 20px;
}

#contentO4 label {
  color: #216c98;
  top: -20px;
  left: 9px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

.containerO4 input[type="date"] {
  padding-left: 7px;
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
  font-size: 15px;
  outline-color: #216c98;
}

.oppo b {
  margin-left: 47%;
}

.containerO5 {
  margin-top: 20px;
  display: grid;
  height: 60px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-right: 10px;
  margin-left: 5px;
}

#contentO5 label {
  color: #216c98;
  top: -20px;
  left: 9px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

#contentO5 img {
  position: absolute;
}

.avatar__image {
  margin-top: 13px;
  margin-left: 5px;
  height: 30px;
  width: 30px;
  border: 1px solid rgb(187, 184, 184);
  border-radius: 20px;
}

#contentO5 input[type="text"] {
  z-index: -1;
}

.containerO6 {
  margin-top: -1px;
  display: grid;
  height: 60px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-right: 10px;
  margin-left: 5px;
}

#contentO6 label {
  color: #216c98;

  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

#currency {
  text-transform: uppercase;
}

/* ---------save and cancel in opportunity----------- */
.saveandcancel {
  margin-top: 3%;
  margin-left: 45%;
  height: 100px;
}

#reset1 {
  width: 70px;
  padding: 8px;
  color: maroon;
  border-radius: 20px;
  border-color: red;
  font-size: 11px;
}

#save1 {
  width: 70px;
  padding: 8px;
  color: green;
  border-radius: 20px;
  border-color: green;
  font-size: 11px;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
.rdtPicker thead tr:first-of-type th {
  cursor: pointer;
  font-size: 13px;
}
.rdtOpen .rdtPicker {
  border: 2px solid #216c98;
  width: 100%;
}
.rdtPicker .dow {
  font-size: 11px;
}
.rdtPrev span,
.rdtNext span {
  font-size: 20px;
}
.errormsg {
  font-size: 12px;
  color: red;
  top: -20px;
  left: 5px;
  font-weight: bold;
  position: relative;
}
