body {
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
}

.opportunitydata {
  padding-top: 68px;
  margin-left: 69px;
}

#opportunitiesheader1 {
  padding-top: 14px;
  height: 50px;
  width: 100%;
  background: rgb(231, 231, 231);
  text-align: center;
  font-weight: bold;
  color: rgb(9, 9, 9);
  border: 1px solid rgb(83, 83, 83);
  border-radius: 1px;
}

.dataleft {
  margin-top: 5px;
  width: 49.5%;
  float: left;
  margin-bottom: 30px;
}

.containerD1,
.containerD2 {
  height: 59px;
}

#contentD2 label,
#contentD3 label,
#contentD4 label {
  top: -20px;
  margin-left: 5px;
  color: #216c98;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

.containerD3,
.containerD4 {
  display: grid;
  height: 59px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

#contentO62 input[type="text"],
#contentO61 input[type="text"],
#contentO6 input[type="text"],
#contentO5 input[type="text"],
#contentO52 input[type="text"],
#contentD4PermissionType input[type='text'],
#contentO53 input[type="text"]{
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-weight: lighter;
  font-size: 13px;
  outline-color: #216c98;
  padding-left: 5px;
}

#contentO3 input[type="text"]{
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-weight: lighter;
  font-size: 13px;
  outline-color: #216c98;
  padding-left: 5px;
}
.containerD5 {
  display: grid;
  height: 59px;
  grid-template-columns: 2fr 1fr 1fr;
  width: 100%;
}

#contD5 {
  padding-left: 7px;
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
  font-size: 15px;
  outline-color: #216c98;
}

#contentD5 label {
  top: -20px;
  margin-left: 5px;
  color: #216c98;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

#contentD3A label {
  top: -20px;
  margin-left: 5px;
  color: #216c98;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

#contentD1 input[type="text"],
#contentD2 input[type="text"],
#contentD3 input[type="text"],
#contentD5 input[type="text"] {
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
  font-size: 13px;
  outline-color: #216c98;
  padding: 5px;
}

.dataright {
  margin-top: 5px;
  width: 49%;
  float: right;
}

.containerD6 {
  display: grid;
  height: 59px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

#contentD6 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

.containerD7 {
  display: grid;
  height: 59px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

#contentD7 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

.containerD8 {
  display: grid;
  height: 59px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

#contentD8 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

.containerD9 {
  display: grid;
  height: 59px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

#contentD9 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

.containerD10 {
  display: grid;
  height: 59px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}
#contentD4PermissionType label,
#contentO61 label,
#contentD10 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

.dataright input[type="number"] {
  padding-left: 7px;
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
  font-size: 14px;
  outline-color: #216c98;
}

.dataright input::-webkit-outer-spin-button,
.dataright input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.leftdetail {
  width: 100%;
  padding-left: 68px;
  padding-top: 30px;
}

.hidden1 {
  display: none;
}

.bbtndetails {
  width: 99.6%;
  margin-left: 1px;
  border: none;
  font-style: normal;
  font-size: 15px;
  height: 45px;
  border: 1px solid gray;
}

#oppodetails {
  width: 100%;
}

.containerDleft {
  display: grid;
  margin-top: 1px;
  width: 49.5%;
  float: left;
  grid-template-columns: 1fr 1fr;
}

.containerD11 {
  height: 59px;
  width: 100%;
  padding-left: 3px;
}

#contentD11 {
  margin-right: 6px;
  height: 58px;
}

#contentD11 label {
  color: #216c98;
  top: -40px;
  left: 5px;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

.containerD11 textarea {
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
  font-size: 14px;
  outline-color: #216c98;
}

#contentD12 {
  height: 59px;
}

#contentD12 input[type="text"],
#contentD13 input[type="text"] {
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-weight: lighter;
  font-size: 14px;
  outline-color: #216c98;
}

#contentD12 select,
#contentD14 select,
#contentD15 select,
#contentD16 select,
#contentD17 select {
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-weight: lighter;
  font-size: 14px;
  outline-color: #216c98;
  background-color: white;
}

#contentD12 label {
  top: -20px;
  margin-left: 5px;
  color: #216c98;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

#contentD13 label {
  top: -20px;
  margin-left: 5px;
  color: #216c98;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

#contentD14 label {
  top: -20px;
  margin-left: 5px;
  color: #216c98;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

#contentD13 input[type="date"] {
  padding-left: 7px;
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
  font-size: 15px;
  outline-color: #216c98;
}


.containerDright {
  display: grid;
  margin-top: 1px;
  margin-right: 4px;
  width: 49%;
  float: right;
  grid-template-columns: 1fr 1fr;
}

#contentD15 {
  height: 59px;
}

#contentD15 label {
  top: -20px;
  margin-left: 5px;
  color: #216c98;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

#contentD16 label {
  top: -20px;
  margin-left: 5px;
  color: #216c98;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

#contentD17 label {
  top: -20px;
  margin-left: 5px;
  color: #216c98;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

.containerBS {
  width: 100%;
  margin-top: 30px;
  padding-left: 68PX;

}

.left1 {
  width: 50%;
  float: left;
  position: relative;
}

.right1 {
  width: 50%;
  float: right;
  position: relative;
}

.content9BS {
  height: 30px;
  width: 100%;

}

.bbtn {
  width: 99%;
  margin-left: 1px;
  border: none;
  font-style: normal;
  font-size: 15px;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  height: 45px;
  border: 1px solid gray;
}

#billOne {
  width: 99%;
  margin-left: -3px;
  padding-bottom: 30px;

}

#billOne label {
  font-size: 12px;
  color: #216c98;
  top: -25px;
  left: 15px;
  position: relative;
}

#billOne input {
  border: 1px solid lightgray;
  height: 30px;
  margin-left: 1.5%;
  padding-left: 80px;
  outline-color: #216c98;
}

.street1BS {
  width: 50%;
  height: 30px;
  float: left;
}

.street2BS {
  width: 50%;
  height: 30px;
  float: right;
}

#pnoBS {
  height: 30px;
  width: 100%;
  appearance: none;
}

.billingBS,
input[type="number"] {
  width: 100%;
}



.billingBS,
input[type="number"]::-webkit-outer-spin-button,
.billingBS,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.billingBS {
  background: url('https://css.gg/pen.css');
  background-size: 20px
}

.content10BS {
  height: 30px;
}

.sbtn {
  width: 98%;
  margin-left: 1.5%;
  border: none;
  font-style: normal;
  font-size: 15px;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  height: 45px;
  border: 1px solid gray;
}

#ship {
  width: 98%;
  margin-left: 0.8%;
  padding-bottom: 30px;
}

#ship label {
  font-size: 12px;
  color: #216c98;
  top: -25px;
  left: 15px;
  position: relative;
}

#ship input {
  height: 30px;
  margin-left: 1.5%;
  padding-left: 72px;
  outline-color: #216c98;
  border: 1px solid lightgray;
}

.hidden {
  display: none;
}



#tblStocksQuotes {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#tblStocksQuotes th {
  border: 1px solid rgb(168, 167, 167);
  padding: 8px;
  text-overflow: ellipsis;
  padding-left: 5px;
  text-align: left;
  background-color: #f5f5f5;
  color: black;
  font-size: 14px;
}

#tblStocksQuotes td {
  width: 20px;
  color: black;
  padding: 8px;
  border-bottom: 1px solid rgb(168, 167, 167);
}

#tblStocksQuotes tr {
  height: 20px;
  color: black;
  font-size: small;
}

#tblStocksQuotes tr:nth-child(even) {
  background-color: #f2f2f2;
}

#tblStocksQuotes tr:hover {
  background-color: #ddd;
}


.ellipsisquotes th {
  white-space: nowrap;
}

.listquotes {
  padding-left: 67px;
}

.xlbutton1 {
  margin-top: 20px;
  padding-left: 3%;
  color: #161717;
  background-color: white;
  border: none;
  font-size: 20px;
}

.downloadandreload {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 8px;
  padding-top: 30px;
  padding-left: 68px;
  width: 100%;
}

.total {
  display: inline-flex;
  width: 100%;
}

.downloadquotes label {
  font-size: 7px;
}

.quoteshead {
  align-self: center;
  padding-left: 40%;
  margin-top: 20px;
}

.reload {
  margin-top: 20px;
  padding-left: 90%;
}

.reload label {
  font-size: 7px;
}

.reload span {
  padding-left: 6px;
}

.plus-quotes {
  margin-top: 50px;
  padding-left: 49%;
}

.plus-quotes a {
  text-decoration: none;
  color: #216c98;
}

.deleteoppo {
  margin-top: 4%;
  padding-left: 41%;
  padding-bottom: 20px;
}

#mainsidebaroppo {
  transition: 0.5s ease;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
}

#mainpage {
  transition: 0.5s ease;
  padding-right: 10px;
  padding-left: 4px;
}

.delete_opportunity {
  top: 10%;
  padding: 8px;
  color: rgb(190, 21, 21);
  border-radius: 24px;
  background-color: rgb(237, 235, 235);
  text-decoration: none;
  border: 2px solid rgb(242, 7, 7);
  font-size: 8px;
  margin-left: 20px;
  font-weight: bold;
}

.update_opportunity {
  top: 10%;
  padding: 8px;
  color: rgb(21, 190, 27);
  border-radius: 24px;
  background-color: rgb(237, 235, 235);
  text-decoration: none;
  border: 2px solid rgb(11, 117, 4);
  font-size: 8px;
  font-weight: bold;
}