body {
  font-family: Arial, Helvetica, sans-serif;
}

.rowaccess {
  display: flex;
  padding-left: 66px;
  padding-top: 64px;
  background-color: white;
}

.rightaccess {
  flex: 75%;
  width: 80%;
  margin-left: 21.2%;
  background-color: white;
  padding-right: 1px;
  padding-top: 3px;
}

#headeraccess {
  padding-top: 15px;
  height: 47px;
  width: 100%;
  background: rgb(231, 231, 231);
  text-align: center;
  font-weight: bold;
  color: rgb(9, 9, 9);
  border: 1px solid rgb(83, 83, 83);
  border-radius: 1px;
  position: relative;
}


.grid-access {
  display: grid;
  grid-template-columns: 1.2fr 2fr;
}

#left-grid-access {
  height: 180px;
  border-bottom: 0.1px solid rgb(218, 217, 217);
}

#left-grid-access i {
  margin-top: 20px;
  margin-left: 38%;
  color: rgb(215, 213, 213);
  font-size: 95px;
  margin-bottom: 20px;
}

#left-grid-access label {
  color: #216c98;
  top: 40px;
  left: 35px;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

#left-grid-access p {
  color: #216c98;
  margin-top: -25px;
  margin-left: 55%;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
}


.containerAccess1,
.containerAccess2,
.containerAccess3,
.containerAccess7,
.containerAccess8,
.containerAccess9 {
  display: grid;
  height: 60px;
  grid-template-columns: 1fr 1fr;
}

.containerAccess4 {
  display: grid;
  height: 60px;
  grid-template-columns: 1fr 0.455fr;
}

.containerAccess5 {
  display: grid;
  height: 60px;
  grid-template-columns: 1fr;
  border: 0.1px solid rgb(218, 217, 217);
}

.containerAccess6 {
  display: grid;
  border: 0.1px solid rgb(218, 217, 217);
  height: 60px;
  grid-template-columns: 1fr;
}

.containerAccess1 label,
.containerAccess2 label,
.containerAccess3 label,
.containerAccess4 label,
.containerAccess7 label,
.containerAccess8 label,
.containerAccess9 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

.containerAccess6 label {
  color: #216c98;
  top: 35px;
  left: -90px;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

.containerAccess1 input[type="text"],
.containerAccess2 input[type="text"],
#contentAccess5 input[type="number"],
#contentAccess6 input[type="email"],
.containerAccess7 input[type="text"],
#contentAccess8 input[type="number"],
#contentAccess12 input[type="date"] {
  padding-left: 3px;
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 60px;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
  font-size: 14px;
  outline-color: #216c98;
}

#contentAccess9 input[type="checkbox"] {
  margin-top: 3px;
  vertical-align: middle;
  height: 20px;
  width: 20px;
  margin-left: 90px;

}

.containerAccess8 select,
.containerAccess9 select,
#contentAccess11 select {
  padding-left: 7px;
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 60px;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
  font-size: 14px;
  outline-color: #216c98;
  background-color: white;
}

#contentAccess7 {
  border: 0.1px solid rgb(218, 217, 217);
  height: 60px;
}

#contentAccess5,
input[type="number"]::-webkit-outer-spin-button,
#contentAccess5,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.removeandresend {
  margin-top: 2%;
  margin-left: 30%;
  height: 100px;
}

#removeaccess {
  width: 120px;
  padding: 6px;
  color: maroon;
  border-radius: 20px;
  border-color: red;
  font-size: 11px;
}

#resendemail {
  margin-left: 10px;
  width: 110px;
  padding: 6px;
  color: rgb(34, 145, 164);
  border-radius: 20px;
  border-color: rgb(0, 109, 128);
  font-size: 11px;
}

.role1 {
  margin-top: 10px;
}

.role1 b {
  margin-left: 48%;
}

.roles1 i {
  margin-left: 33%;
  color: rgb(122, 121, 121);
  font-size: 13px;
}

#contentAccess10 {
  margin-top: -25px;
  margin-left: 70%;
}

#deleteandtoken {
  width: 160px;
  padding: 6px;
  color: maroon;
  border-radius: 20px;
  border-color: red;
  font-size: 11px;
}

.refernce1 {
  margin-top: 15px;
  margin-left: 45%;
  margin-bottom: 15px;
}

.loginemailpswd {
  display: grid;
  border: 0.1px solid rgb(218, 217, 217);
  height: 70px;
  grid-template-columns: 1fr;
  text-align: center;
}

.loginemailpswd b {
  margin-top: 10px;
}

.loginemailpswd i {
  font-size: 12px;
}

.loginEml {
  display: grid;
  border: 0.1px solid rgb(218, 217, 217);
  height: 40px;
  grid-template-columns: 1fr;
  text-align: center;
  margin-bottom: 10px;
}

.loginEml b {
  margin-top: 10px;
}

#lang {
  text-transform: uppercase;
}

.place::holder {
  color: #ccc;
  font-size: 10px;
}

#place::placeholder {
  font-size: 11px;
  display: flex;
  justify-content: left;
  align-items: start;
  font-style: italic;
}

#securityaccesslabel {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 11px;
  color: #216c98;
  font-weight: bold;
  padding: 3px;
}

#langlabel {
  position: absolute;
  top: 513px;
  left: 326px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 11px;
  color: #216c98;
  font-weight: bold;
}

#contentaccessinput {
  width: 100%;
  height: 59px;
  border: 1px solid #ccc;
  outline-color: #216c98;
  padding: 8px;
}

#langaccessinput {
  width: 100%;
  height: 59px;
  border: 1px solid #ccc;
  outline-color: #216c98;
  padding: 8px;
}

#orgaccessinput {
  width: 100%;
  height: 59px;
  border: 1px solid #ccc;
  outline-color: #216c98;
  padding: 8px;
}

#timeaccessinput {
  width: 100%;
  height: 59px;
  border: 1px solid #ccc;
  outline-color: #216c98;
  padding: 8px;
}

#securitylist {
  position: relative;
  top: -16px;
  list-style: none;
  padding: 10px;
  width: 100%;
  height: 105px;
  border: 1px solid #ccc;
  background: white;
  cursor: pointer;
  font-size: 12px;
  z-index: 1;
  overflow-y: scroll;
}

#langlist {
  position: relative;
  top: -16px;
  list-style: none;
  padding: 10px;
  width: 100%;
  height: 105px;
  border: 1px solid #ccc;
  background: white;
  cursor: pointer;
  font-size: 12px;
  z-index: 1;
  overflow-y: scroll;
}

#timelist {
  position: relative;
  top: -16px;
  list-style: none;
  padding: 10px;
  width: 100%;
  height: 105px;
  border: 1px solid #ccc;
  background: white;
  cursor: pointer;
  font-size: 12px;
  z-index: 1;
  overflow-y: scroll;
}

#orglist {
  position: relative;
  top: -16px;
  list-style: none;
  padding: 10px;
  width: 100%;
  height: 105px;
  border: 1px solid #ccc;
  background: white;
  cursor: pointer;
  font-size: 12px;
  z-index: 1;
  overflow-y: scroll;
}

.caret-icon {
  color: #216c98;
  top: -38px;
  position: relative;
  float: right;
  right: 20px;
}

uritylistitems #langlabel {
  font-size: 14px;
  color: #333;
  margin-right: 10px;
  top: 513px;
  left: 330px;
}

#timelist li:hover {
  background-color: #045679;
  color: white;
}

#langlist li:hover {
  background-color: #045679;
  color: white;
}


#securitylist li:hover {
  background-color: #045679;
  color: white;
}

#orglist li:hover {
  background-color: #045679;
  color: white;
}

#securitylistitems {
  padding: 4px;
  font-size: 12px;
}

#langlistitems {
  padding: 4px;
  font-size: 12px;
}

#timelistitems {
  padding: 4px;
  font-size: 12px;
}

#orglistitems {
  padding: 4px;
  font-size: 12px;
}

#orglabel {

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 11px;
  color: #216c98;
  font-weight: bold;
}


#timelabel {

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 11px;
  color: #216c98;
  font-weight: bold;
}


#plusiconaccess {

  padding: 15px;

  margin-top: 40%;

  margin-left: 90px;

  position: fixed;

  cursor: pointer;

  text-decoration: none;
  color: #216c98;


}

div#accessmsgdiv {
  position: relative;
  top: 34px;
}

label#accessmsg {
  position: relative;
  left: 305px;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  color: #ccc;
  font-weight: bold;
  cursor: pointer;
  top: -18px;
}

.extrainput {
  padding-left: 7px;
  border: 0.1px solid rgb(218, 217, 217);

  height: 57px;
  font-size: medium;
  font-weight: lighter;
  font-size: 12px;
  outline-color: #216c98;
  width: 100%;
}





#toggleextra {

  color: #0f6b93;

  position: relative;

  float: right;

  top: -37px;

  right: 22px;

}

#labelextra {

  text-align: center;


  top: -20px;
  margin-left: 4px;
  color: #216c98;
  font-weight: bold;
  font-size: 11px;
  position: relative;


}

ul#extralist {

  z-index: 1;

  position: relative;

  background-color: #ffffff;

  border: 1px solid #ccc;

  padding: 1px;

  list-style: none;

  top: -18px;

}

ul#extralist li:hover {

  background-color: #0f6b93;

  color: white;

}




ul#extralist li {

  cursor: pointer;

  padding: 5px;

  font-size: 10px;

  font-weight: bold;

}

#unique {
  margin-top: 23px;
  width: 40%;
}

#notelist {
  position: relative;
  top: -16px;
  list-style: none;
  padding: 10px;
  width: 100%;
  height: 77px;
  border: 1px solid #ccc;
  background: white;
  cursor: pointer;
  font-size: 12px;
  z-index: 1;

}

#noteslabel {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-weight: bold;
  font-size: 11px;
  position: relative;
}

#sendmail{
  margin-left: 475px;
  width: 110px;
  padding: 6px;
  color: rgb(34, 145, 164);
  border-radius: 20px;
  border-color: rgb(0, 109, 128);
  font-size: 11px;
  margin-top: 5px;
  margin-bottom: 15px;
}



#notelistitems {
  padding: 4px;
  font-size: 11px;
}

#notelist li:hover {
  background-color: #045679;
  color: white;
}

#noteaccessinput {
  width: 100%;
  height: 59px;
  border: 1px solid #ccc;
  outline-color: #216c98;
  padding: 8px;
  font-size: 12px;
  font-weight: bold;

}

#notelistitem {
  padding: 8px;
  font-weight: lighter;
}

