body {
  font-family: Arial, Helvetica, sans-serif;
}

* {
  box-sizing: border-box;
}

.row {
  display: flex;
  background-color: white;
  margin-left: 68px;
  padding-top: 60px;
}

.left {
  flex: 35%;
  background-color: white;
  border-right: 1px solid black;
  width: 25%;
}

.plusicon {
  text-align: center;
  padding: 20px;
  color: #0f6b93;
  text-decoration: none;
}

.plusicon a {
  color: #0f6b93;
  text-decoration: none;
}

.divide {
  overflow-y: scroll;
  height: 430px;
}

.right {
  flex: 75%;
  margin-left: 21.3%;
  background-color: white;
  z-index: 1;
  padding-right: 2px;
  top: 10px;
  position: relative;

}

.slide {
  z-index: 2;
  position: relative;
}

.rightbar {
  overflow: scroll;
}

#accountsheader {
  padding-top: 15px;
  height: 48px;
  width: 100%;
  background: rgb(231, 231, 231);
  text-align: center;
  font-weight: bold;
  color: rgb(9, 9, 9);
  border-radius: 1px;
  border: 1px solid #ccc;
  z-index: 2;
}


.containerA1 {
  padding-top: 7px;
  display: grid;
  height: 60px;
  grid-template-columns: 2.7fr 1.2fr;
}

#content1 {
  width: 100%;
}

/* #content1 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
} */
.accountname{
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}
.inputTextA1 {
  padding-left: 7px;
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  border-left: 3px solid;
  height: 59px;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
  font-size: 14px;
  outline-color: #216c98;
}

#content2 {
  border-radius: 1px;
  width: 100%;
  height: 60px;
}

#content2 label {
  color: #216c98;
  top: -38px;
  left: 30px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

#content5 select,
#content6 select,
#content7 select,
#content8 select {
  padding-left: 7px;
  border: 0.1px solid rgb(218, 217, 217);
  border-radius: 1px;
  height: 59px;
  width: 100%;
  font-weight: lighter;
  font-size: 11px;
  outline-color: #216c98;
}

.containerA2 {
  padding-top: 6px;
  display: grid;
  height: 60px;
}

#content3 {
  height: 59px;
  width: 100%;
  background-color: rgb(213, 215, 215);
}

#content3 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

.containerA3 {
  padding-top: 5px;
  display: grid;
  height: 60px;
  grid-template-columns: 2.7fr;
}

#content4 {
  height: 59px;
  width: 100%;
}

#content4 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

.containerA4 {
  padding-top: 4px;
  display: grid;
  height: 60px;
  grid-template-columns: 1fr 1fr;
}

#content5 {
  border-radius: 1px;
  height: 59px;
  width: 100%;
  background-color: rgb(213, 215, 215);
}

#content5 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

#content6 {
  border-radius: 1px;
  width: 100%;
  height: 59px;
  background-color: rgb(215, 228, 224);
}

#content6 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

.containerA5 {
  padding-top: 3px;
  display: grid;
  height: 60px;
  grid-template-columns: 1fr 1fr;
}

#content7 {
  border-radius: 1px;
  height: 59px;
  width: 100%;
  background-color: rgb(213, 215, 215);
}

#content7 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

#content8 {
  border-radius: 1px;
  width: 100%;
  height: 59px;
  background-color: rgb(215, 228, 224);
}

#content8 label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}

.savcanc {
  margin-top: 4%;
  margin-left: 42%;
  height: 50px;
  display: inline-flex;
}

.containerA6 {
  width: 100%;
  margin-top: 61px;
  position: relative;
  bottom: 16px;
}

.left1a {
  width: 50%;
  float: left;
}

.right1a {
  width: 50%;
  float: right;
}

.content9 {
  height: 30px;
  width: 100%;

}

.bbtn {
  width: 99%;
  margin-left: 1px;
  border: none;
  font-style: normal;
  font-size: 15px;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  height: 45px;
  border: 1px solid #ccc;
}


#billOne {
  width: 99%;
  margin-left: -1px;
  padding-bottom: 30px;
}

#billOne label {
  font-size: 12px;
  color: #216c98;
  top: -25px;
  left: 15px;
  position: relative;
}

#billOne input {
  border: 1px solid lightgray;
  height: 30px;
  margin-left: 1.5%;
  padding-left: 80px;
  outline-color: #216c98;
}

.street1 {
  width: 50%;
  height: 30px;
  float: left;
}

.street2 {
  width: 50%;
  height: 30px;
  float: right;
}

#pno {
  height: 30px;
  width: 100%;
  appearance: none;
}

.billing,
input[type="number"] {
  width: 100%;
}



.billing,
input[type="number"]::-webkit-outer-spin-button,
.billing,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.billing {
  background: url('https://css.gg/pen.css');
  background-size: 20px
}

.content10 {
  height: 30px;
}


.input-container-creataccount:focus-within .tooltip {
  display: block;
}

#tooltip:hover{
  background-color: #216c98;
  color: white;
}
#tooltipaccount {
  display: none;
  position: absolute;
  color: black;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 1px;
  width: 99.7%;
  text-align: center;
  border-top: none;
  font-size: 12px;
  font-weight: bold;
}
#tooltipaccount:hover{
  background-color: #216c98;
  color: white;
}
#ownerimage {
  position: relative;
  top: -49px;
  list-style: none;
  padding: 10px;
  width: 10px;
  left: 13px;
  background: white;
  cursor: pointer;
  font-size: 12px;
  border: 0.1px solid #ccc;
  border-radius: 25px;
  background-color: #ccc;
}
#ship {
  width: 98%;
  margin-left: 0.8%;
  padding-bottom: 30px;
}

#ship label {
  font-size: 12px;
  color: #216c98;
  top: -25px;
  left: 15px;
  position: relative;
}

#ship input {
  height: 30px;
  margin-left: 1.5%;
  padding-left: 72px;
  outline-color: #216c98;
  border: 1px solid lightgray;
}

.hidden {
  display: none;
}

.opportunities {
  padding-top: 80px;
}

.opportunities b {
  margin-left: 42%;
}

.opportunities a {
  text-decoration: none;
  color: rgb(15, 15, 15);
}

.opportunities table {
  color: black;
  font-size: .9em;
  font-weight: 300;
  line-height: 40px;
  border-collapse: separate;
  border-spacing: 0;
  border: 0.5px solid #1b1718;
  width: 100%;
  margin: 10px auto;

}

.opportunities th {
  background: #f5f4f4;
  color: #110f0f;
  border-bottom: 0.1px solid gray;
  border-left: 0.1px solid rgb(176, 172, 172);
}

.opportunities td {
  border-bottom: 0.1px solid gray;
}

.opportunities td:hover:not(th) {
  background-color: rgb(218, 217, 217);
}

.opportunities input[type="button"] {
  transition: all .3s;
  border: 1px solid #ddd;
  padding: 6px 12px;
  text-decoration: none;
  border-radius: 1px;
  font-size: 12px;
}

.opportunities input[type="button"]:not(.active) {
  background-color: transparent;
}

.opportunities .active {
  background-color: gray;
  color: #fff;
}

.opportunities input[type="button"]:hover:not(.active) {
  background-color: #ddd;
}

.plus-oppertunities {
  margin-top: 10px;
  margin-left: 48%;
}

.plus-oppertunities a {
  text-decoration: none;
  color: #216c98;
}

.delete_account {
  height: 30px;
  width: 70px;
  color: maroon;
  border-radius: 20px;
  border-color: red;
  font-variant: all-small-caps;
  font-size: 11px;
  width: 20%;
}

.update_account {
  height: 30px;
  width: 70px;
  color: green;
  border-radius: 20px;
  border-color: green;
  margin-right: 20px;
  font-variant: all-small-caps;
  font-size: 11px;
  width: 20%;
}

.save_account {
  height: 30px;
  width: 70px;
  color: green;
  border-radius: 20px;
  border-color: green;
  margin-right: 20px;
  font-variant: all-small-caps;
  font-size: 11px;
  width: 17%;
}

.error-border {
  border-left: 3px solid red;
}
.cancel_account{
  height: 30px;
  width: 70px;
  color: maroon;
  border-radius: 20px;
  border-color: red;
  font-variant: all-small-caps;
  font-size: 11px;
  width: 18%;
}
#save_cancel{
margin-left: 35%;
 align-items: center;
 justify-content: center;
  display: none;
}

.error-border {
  border-left: 6px solid red;
}

.deleteacc {
  margin-top: 6%;
  margin-left: 40%;
}

.deleteacc b {
  margin-left: 45px;
}

.deleteacc p {
  padding-top: 20px;
  padding-bottom: 50px;
  margin-left: 45px;
  color: gray;
}

#tblStocks {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#tblStocks td,
#tblStocks th {
  border: 1px solid #ddd;
  padding: 8px;
  border-color: rgba(0, 0, 0, 0.1);
  text-overflow: ellipsis;
}

#tblStocks td {
  border: none;
}

#tblStocks tr:nth-child(even) {
  background-color: #f2f2f2;
}

#tblStocks tr:hover {
  background-color: #ddd;
}

#tblStocks th {

  padding-left: 5px;
  text-align: left;
  background-color: #f5f5f5;
  color: black;
}

#tblStocks td {
  width: 20px;
  color: black;
}

#tblStocks tr {
  height: 20px;
  color: black;
  font-size: small;
}

.ellipsis th {
  white-space: nowrap;
}

.list {
  margin-left: 67px;
  overflow-y: scroll;
}

.xlbutton {
  margin-top: 25px;
  margin-left: 96.5%;
  color: #0f6b93;
  background-color: white;
  border: none;
  padding: 5px;
  font-size: 20px;
}


.acl {
  padding-top: 45px;
  text-align: center;
  margin-left: 20px;
  background-color: #f5f5f5;
  padding: 12px;
}

.pagination {
  margin-left: 39%;

}

.pagination button {
  height: 30px;
  width: 30px;
  position: relative;
  margin-top: 10px;
}


.oppotable {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  top: 20px;
  position: relative;
}

.oppotable thead th {
  background-color: #f2f2f2;
  text-align: left;
  padding: 8px;
  padding: 10px;
  text-align: left;
  font-size: 13px;
}

.oppotable tbody td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
  cursor: pointer;
  background-color: white;
  font-size: 14px;

}

.oppotable tbody td:hover {
  background-color: #a0a0a0;
}

/* ****************************************************************************************************** */
.dropdown-input,
.contentinput6,
.content8input,
.content7input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 1px;
  width: 100%;
  height: 59px;
  outline-color: #0f6b93;
}
#contentO4 {
  height: 59px;
}
.content1input {
  padding-left: 60px;
  border: 1px solid #ccc;
  border-radius: 1px;
  width: 100%;
  height: 59px;
  outline-color: #0f6b93;
}

.no-results,
.dropdown-list-item,
.dropdown-list-item-content1,
.dropdown-list-item-content5,
.dropdown-list-item-content7,
.dropdown-list-item-content8 {
  padding: 5px;
  cursor: pointer;
  font-size: 13px;
}

.dropdown-icon-content1,
.dropdown-icon-content8,
.dropdown-icon-content7,
.dropdown-icon-content5,
.dropdown-icon {
  position: relative;
  font-size: 14px;
  color: #216c98;
  right: 9px;
  top: -38px;
  float: right;
}

.dropdown-list,
.content1dropdown,
.content8dropdown,
.content7dropdown,
.content5dropdown {
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  top: -16px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 110px;
  overflow-y: auto;
  display: block;
  z-index: 1;
  padding: 7px;
}

.content7vertical,
.content1owner,
.content8type,
.content5label {
  color: #216c98;
  top: -20px;
  left: 5px;
  font-size: small;
  font-size: 11px;
  font-weight: bold;
  position: relative;
}

#dropdownlist :hover,
.content1dropdown :hover,
.content7dropdown :hover,
.content8dropdown :hover,
.content5dropdown :hover {
  background-color: #216c98;
  color: white;
}