body {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .whereused {
    height: 134px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-right: 1px;
    margin-left: 66px;
  }
  
  #searchh {
    margin-top: 64px;
  
  }
  
  #searchh select {
    height: 64px;
    font-size: 13px;
    color: black;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 2px;
    outline-color: #0f6b93;
    background-color: white;
  }
  
  #searchh label {
    top: 15px;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 4px;
    color: #0f6b93;
    font-size: 11px;
    font-family: sans-serif;
    position: relative
  }
/*   
.custom-dropdown {
  position: relative;
  display: inline-block;
  margin-top: 62px;
} */

.dropdown-header {
  border: 1px solid rgb(204, 204, 204);
  padding: 1px;
  cursor: pointer;
  outline-color: #216c98;
  height: 66px;
}
.dropdown-options {
  position: absolute;
  top: 65px;
  left: 0;
  z-index: 1;
  width: 100%;
}

.dropdown-options input {
  width: 100%;
  padding: 3px;
  outline-color:#045679 ;

}

.dropdown-options ul {
  list-style: none;
  margin: 0;
  padding: 4px;
  background-color: white;
  border: 1px solid #ccc;
 
}

.dropdown-options li {
  padding: 10px;
  cursor: pointer;
  font-size: 15px;
}

.dropdown-options li:hover {
  background-color: #045679;
}
#svtype {
  top: -25px;
  font-weight: bold;
  padding-top: 27px;
  padding-left: 5px;
  color: #0f6b93;
  font-size: 11px;
  font-family: sans-serif;
  position: relative;
}
#searchtypespan {
  top: 12px;
  padding-top: 27px;
  padding-left: 5px;
  font-weight: bold;
  font-size: 15px;
  position: relative;
}
#iconanle {
  padding-top: 4px;
  padding-left: 175px;
  color: #0f6b93;
  font-size: 11px;
  position: relative;
}


  #consulting {
    margin-top: 64px;
  }
  
  #consulting input {
    height: 64px;
    font-size: 15px;
    padding-left: 5px;
    color: black;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 2px;
    background-color: white;
    outline-color: #0f6b93;
  }
  
  #consulting label {
    top: -23px;
    font-weight: bold;
    padding-top: 6px;
    padding-left: 5px;
    color: #0f6b93;
    font-size: 11px;
    font-family: sans-serif;
    position: relative
  }
  
  #consultlabel {
    color: black;
    font-size: 12px;
    position: relative;
    top: 10px;
  }
  

  
  #checkbox1 label {
    top: 80px;
    left: 511px;
    color: grey;
    font-size: 11px;
    font-family: sans-serif;
    position: absolute;
  }
  
  #partial {
    height: 160px;
    left: 486px;
    position: absolute;
  }
  
  #checkbox2 label {
    top: 105px;
    left: 511px;

    color: grey;
    font-size: 11px;
    font-family: sans-serif;
    position: absolute;
  }
  
  #approved {
    height: 15px;
    top: 104px;
    left: 486px;
    position: absolute;
  }
  
  
  
  
  .rightwhereused {
    flex: 75%;
    padding-left: -9px;
    margin-left: 64px;
    background-color: white;
    z-index: 1;
  }
  
  #header {
    margin-top: -6px;
    padding-top: 12px;
    padding-left: 2px;
    height: 44px;
    width: 100%;
    background: rgb(231, 231, 231);
    text-align: center;
    font-weight: bold;
    color: rgb(9, 9, 9);
    border-radius: 1px;
  }
  
  
  #calendar1 {
    top: -1px;
    position: relative;
    position: relative;
      height: 129px;
      font-size: 15px;
      color: black;
      border: 0.1px solid rgb(218, 217, 217);
      border-radius: 2px;
      background-color: white;
      outline-color: #0f6b93;
  }
  
  
  .input-container input {
    border: 1px solid black;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    border: 0.1px solid rgb(218, 217, 217);
    height: 25px;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  
  #calendar1 input[type="date"] {
    padding-left: 7px;
    margin-top: 64px;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 1px;
    height: 65px;
    width: 100%;
    font-size: medium;
    font-weight: lighter;
    font-size: 14px;
    color: #216c98;
    outline-color: #216c98;
    position: relative;
  }
  
  #calendar1 label {
    font-size: 12px;
    position: relative;
    top: -23px;
    font-weight: bold;
    padding-left: 9px;
    color: #216c98;
    z-index: 2;
  }
  
  .fromcalendar {
    padding-left: 7px;
    margin-top: 64.5px;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 1px;
    height: 63px;
    width: 100%;
    font-size: medium;
    font-weight: lighter;
    font-size: 15px;
    color: #216c98;
    outline-color: #216c98;
    position: relative;
  }
  
  .from::placeholder {
    font-style: italic;
  }
  
  #my_date_picker {
    top: 20px;
    position: relative;
  }
  
  #calendar2 {
    top: -1px;
    position: relative;
  }
  
  #calendar2 input[type="date"] {
    padding-left: 7px;
    margin-top: 64px;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 1px;
    height: 65px;
    width: 100%;
    font-size: medium;
    font-weight: lighter;
    font-size: 15px;
    color: #216c98;
    outline-color: #216c98;
    position: relative;
  }
  
  #calendar2 label {
    font-size: 12px;
    font-weight: bold;
    position: relative;
    top: -21px;
    padding-left: 9px;
    color: #216c98;
    z-index: 1;
  }
  
  .tocalendar {
    padding-left: 7px;
    margin-top: 65.5px;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 1px;
    height: 63.5px;
    width: 100%;
    font-size: medium;
    font-weight: lighter;
    font-size: 15px;
    color: #216c98;
    outline-color: #216c98;
    position: relative;
    z-index: 1;
  }
  
  .to::placeholder {
    font-style: italic;
  }
  
  .xlbuttonWhereused {
    margin: 19px;
    margin-top: -113px;
    margin-right: 96.5%;
    color: #0f6b93;
    border: none;
    height: 23px;
    width: 14px;
    top: -22px;
    cursor: pointer; 
    position: relative;
    font-size: 20px;
    background-color: white;
  
  
  
    
  
  }
  
  #downloadicon {
    top: 1px;
      left: 1px;
      position: relative;
      font-size: 25px;
      color:#0f6b93;;
  }
  
  #searchclear {
    margin-top: 87px;
    position: relative;
    top: -24px;
    height: 65px;
    border: 0.1px solid rgb(218, 217, 217);
  }
  
  #searchclear select {
    height: 64px;
    font-size: 11px;
    color: black;
    border: 0.1px solid rgb(218, 217, 217);
    border-radius: 2px;
    background-color: white;
    outline-color: #0f6b93;
  }
  
  #myButton {
    margin-top: 18px;
    padding: 6px;
    color: #0f6b93;
    border-radius: 24px;
    background-color: rgb(247, 247, 247);
    opacity: 0.5;
    border: 1.5px solid skyblue;
    font-size: 10px;
    font-weight: bold;
    margin-left: 29px;
    cursor: not-allowed;
  }
  
  #clear {
    margin-top: 18px;
    padding: 6px;
    color: red;
    border-radius: 24px;
    background-color: rgb(247, 247, 247);
    border: 1.5px solid red;
    font-size: 10px;
    font-weight: bold;
    margin-left: 16px;
  }
  
  
 
  
  
  .month-list>div>div {
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
  }
  
  .month-list>div>div:hover {
    background-color: var(--light-btn);
    color: var(--dark-text);
    transform: scale(0.9);
    transition: all 0.2s ease-in-out;
  }
  
  .month-list.show {
    visibility: visible;
    pointer-events: visible;
    transition: 0.6s ease-in-out;
    animation: to-left .71s forwards;
  }
  
  .month-list.hideonce {
    visibility: hidden;
  }
  
  .month-list.hide {
    animation: to-right 1s forwards;
    visibility: none;
    pointer-events: none;
  }
  
  .date-time-formate {
    width: max-content;
    height: max-content;
    font-family: Dubai Light, Century Gothic;
    position: relative;
    display: inline;
    top: 24px;
    justify-content: center;
  }
  
  
  #myBtn {
    border: none;
    cursor: pointer;
    position: relative;
    left: 5px;
  }
  
  
  .dropdown-content1 {
    display: none;
    position: absolute;
    background-color: rgb(231, 231, 231);
    min-width: 193px;
    left: 153px;
    z-index: 1;
    height: 119px;
    top: 171px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  
  
  
  .dropdown-content1 a {
    color: black;
    text-decoration: none;
  }
  
  .dropdown-content1 a:hover {
    background-color: #f1f1f1
  }
 
 
 