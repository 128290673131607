.resetform{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.resetform input{
    height: 40px;
    width: 311px;
    border-radius: 13px;
}

#updatepass{
    height: 35px;
    width: 95px;
    background: black;
    color: white;
    border-radius: 11px;
}

#updatepass:disabled{
    opacity: 0.5;
    cursor: not-allowed;
}

.password-toggle-icon{
    left: -21px;
    position: relative;
}