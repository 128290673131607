 #forgotform {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center; */
}

#forgotform input {
    height: 35px;
    width: 313px;
    border-radius: 13px;
}

#einfo {
    margin-top: 7px;
    width: 100px;
    height: 40px;
    background: black;
    color: white;
    border-radius: 11px;
}