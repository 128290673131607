.sample-tutorial {
    position: relative;
    height: 100%;
    overflow: hidden;
}
.sample-spreadsheets {
    width: calc(100%);
    height:100%;
    overflow: hidden;
    float: left;
}
.options-container {
    float: right;
    width: 280px;
    overflow: auto;
    padding: 12px;
    height: 100%;
    box-sizing: border-box;
    background: #fbfbfb;
}
.option-row {
    margin-bottom: 12px;
}
input[type=button] {
    padding: 4px 6px;
    margin-bottom: 6px;
}
body {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#ss{
    width: 100%;
    height: 100%;
}