


   .sidebar1 {
    height: 100%;
    width: 0;
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    border: 1px solid black;
  }
  
  .sidebar1 a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: rgb(15, 15, 15);
    display: block;
    transform: 0.3s;
  }
  
  .sidebar1 a:hover {
    color: #216c98;
  }
  
  .sidebar1 .closebtn {
    position: absolute;
    top: 0px;
    left: 140px;
    color: rgb(4, 4, 4);
    margin-left: 50px;
  }
  
  .openbtnact {
    width: 15px;
    margin-top: 250px;
    font-size: 20px;
    cursor: pointer;
    background-color: #216c98;
    color: white;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    position: relative;
    height: 60px;
  }
  
  #mainsidebar {
    transition: 0.5s ease;
  }
  
  .navside {
    width: 100%;
    padding-top: 0%;
    margin-left: 0%;
    text-decoration: none;
    color: black;
    border: 1px solid rgb(215, 228, 224);
    flex: 1;
  }
  
  .navside a,
  .dropdown-btn {
    padding: 0px 8px 6px 23px;
    margin-top: 4px;
    text-decoration: none;
    font-weight: 750;
    font-size: 11px;
    color: #045679;
    display: block;
    border: 1px #045679;
    background: white;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
    height: 35px;
    overflow: hidden;
  }
  
  .dropdown-container {
    font-size: 20px;
    color: #045679;
    outline: #045679;
    display: none;
  }
  
  #send {
    width: 100%;
    height: 36px;
    border: none;
    outline: 1px solid rgb(215, 228, 224);
  }
  
  
  #sent {
    height: 43px;
    width: 33px;
    background: transparent;
    border: none;
    padding-left: 87px;
  }
  
  #sent1 {
    margin-left: -31px;
    height: 35px;
    width: 25px;
    background: transparent;
    border: none;
  }
  
  #eid {
    height: 30px;
    padding-left: 17px;
    width: 100%;
    outline-color: #216c98;
    border-radius: none;
    border: 1px solid #ccc;
  }
  
  #eid2 {
    height: 30px;
    padding-left: 17px;
    width: 100%;
    outline-color: #216c98;
    border-radius: none;
    border: 1px solid #ccc;
    margin-top: 7px;
  }
  
  #cfid {
    height: 30px;
    padding-left: 17px;
    width: 100%;
    outline-color: #216c98;
    border-radius: none;
    border: 1px solid #ccc;
    margin-top: 7px;
  }
  
  #lab {
    color: #045679;
    padding-left: 22px;
    font-size: 9px;
  }
  
  #upl {
    font-family: 'robot';
    font-size: 15px;
    position: relative;
    color: #2f2e2e;
    padding-left: 120px;
  }
  
  #fileList {
    font-size: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    list-style: none;
  }
  
  .side-container {
    margin-bottom: 3px;
    display: none;
  }
  
  #time {
    font-size: 9px;
    padding-left: 23px;
    color: #045679;
    height: 30px;
    font-weight: 700;
  }
  
  #time1 {
    float: right;
    font-size: 11px;
    color: black;
    padding-right: 65px;
  }
  
  #lhr {
    padding: 0%;
    margin: 0%;
    opacity: 0.4;
  }
  

  #text-to-copy {
    font-size: 15px;
    height: 20px;
    margin-bottom: 5px;
  }
  
  #cpbtn {
    float: right;
    margin-top: -35px;
    border: none;
    outline: none;
    width: 31px;
    height: 31px;
    border-radius: 20px;
    background: none;
    z-index: 2;
    position: relative;
  }
  
  #cpbtn:hover {
    background-color: rgb(6, 111, 140);
    ;
    transition: 0.5s ease;
    color: white;
  }
  
  .txt {
    width: 85%;
    overflow-y: hidden;
    text-overflow: ellipsis;
  }
  
  #link-to-copy {
    font-size: 15px;
    height: 20px;
    margin-bottom: 5px;
  }
  
  #linkbtn {
    float: right;
    margin-top: -35px;
    border: none;
    outline: none;
    width: 31px;
    height: 31px;
    border-radius: 20px;
    background: none;
  }
  
  #linkbtn:hover {
    background-color: rgb(6, 111, 140);
    transition: 0.5s ease;
    color: white;
  }
  
 #filePlus{
  display: flex;
  flex-direction: column;
  align-items:center;
  font-size: 16px;
 
 }
 #downloadFile{
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
 }
 #savedFileList{
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  border-bottom: 1px solid #045679;
   margin-top: 6px;
   height: 40px;
 }
 #checkFile{
  font-size: 15px;
  margin-top: 6px;
 }
 #oname{
  outline: none;
  border: none;
  margin-left: 20px;
 }